import extend from 'extend';
import Q from 'q';
import _ from 'underscore';
import moment from 'moment';
import store from '@/lib/store';

const collapse = function (pParameter) {
    if (!pParameter) pParameter = {};
    const type = pParameter.type || `open`;
    store.state.data_for_admin_layout.data_for_left_menu.is_open = !store.state.data_for_admin_layout.data_for_left_menu.is_open;
};

export default {
    collapse,
}
