import _ from 'underscore';

export default {
  data() {
    return {
      user_name: `admin`,
      menu_entity_list: [
        {text: `注销`, code: `logout`, enabled: true,},
        {text: `其他`, code: `other`, enabled: false,},
      ],
    };
  },
  methods: {
    __btn_menu_entity_click(pEntity) {
      const __this = this;

      const code = pEntity.code;
      switch (code) {
        case `logout`:
          __this.$router.replace({
            name: `login`,
          });
          break;

        case `other`:
        default:
          console.log(pEntity);
          break
      }
    },
  },
  watch: {},
  created() {
    const __this = this;
  },
}
