import Breadcrumb from './component/breadcrumb/index.vue';
import CurrentUserMenu from './component/current-user-menu';
import HistoryTag from './component/history-tag/index.vue';
import LeftMenu from './component/left-menu/index.vue';
import api from './api';

export default {
    components: {
        Breadcrumb,
        CurrentUserMenu,
        HistoryTag,
        LeftMenu,
    },
    data() {
        return {};
    },
    mounted() {
        const __this = this;

        api.init();
    },
}
