const extend = require(`extend`);
const Q = require(`q`);
const _ = require(`underscore`);
const moment = require(`moment`);

const __all_menu_list = require(`./all_menu_list`);

const get_menu_data = function (pParameter) {
    if (!pParameter) pParameter = {};
    const $router = pParameter.$router;

    const all_menu_list = _.chain(__all_menu_list)
        .map(function (m) {
            const route_name = m.route_name;
            const route_query = m.route_query;

            const r = $router.resolve({
                name: route_name,
                query: route_query,
            });

            let route_path = ``;
            let route_fullPath = ``;
            if (r) {
                if (r.resolved) {
                    if (r.resolved.path) {
                        route_path = r.resolved.path;
                        route_fullPath = r.resolved.fullPath;
                    }
                }
            }

            const o = extend(true, {}, m);
            o.route_path = route_path;
            o.route_fullPath = route_fullPath;
            o.sub_list = [];
            return o;
        })
        .value()
    ;

    const list_level_1 = _.chain(all_menu_list)
        .filter(function (m) {
            return m.level === 1;
        })
        .map(function (m1) {
            const sub_list = _.chain(all_menu_list)
                .filter(function (m2) {
                    return 1 === 1
                        && m2.parent_id === m1.id
                        && m2.level === 2
                        ;
                })
                .value()
            ;
            m1.sub_list = sub_list;
            return m1;
        })
        .value()
    ;

    const list_level_2 = _.chain(all_menu_list)
        .filter(function (m) {
            return m.level === 2;
        })
        .map(function (m2) {
            const sub_list = _.chain(all_menu_list)
                .filter(function (m3) {
                    return 1 === 1
                        && m3.parent_id === m2.id
                        && m3.level === 3
                        ;
                })
                .value()
            ;
            m2.sub_list = sub_list;
            return m2;
        })
        .value()
    ;

    const menu_list = list_level_1;

    const o = {
        menu_list,
        all_menu_list,
    };
    return o;
};

module.exports = get_menu_data;
