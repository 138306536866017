import extend from 'extend';
import Q from 'q';
import _ from 'underscore';
import get_uuid from 'nllib/src/common/get_uuid';
import aRouter from '@/router';

const handler_entity_list = [];

const defer_right_container_animationend = Q.defer();

const __get_default_id = function () {
    const currentRoute = aRouter.currentRoute;
    const fullPath = currentRoute.fullPath;

    let id;
    if (fullPath) {
        id = fullPath;
    } else {
        id = get_uuid();
    }
    return id;
};

const add = function (pParameter) {
    if (!pParameter) pParameter = {};
    const __id__ = pParameter.id;
    const handler = pParameter.handler;

    let id;
    if (__id__) {
        id = __id__;
    } else {
        id = __get_default_id();
    }

    if (!_.isFunction(handler)) {
        const msg = `add_handler parameter error, code=001`;
        console.error(msg);
        console.trace();
        return;
    }

    const query = _.chain(handler_entity_list)
        .find(function (m) {
            const __id = m.id;
            return __id === id;
        })
        .value()
    ;
    if (query) return;

    handler_entity_list.push({
        id,
        handler,
    });
};

const on = add;

const init = function () {
    const $e = document.querySelector(`.right-container`);
    $e.addEventListener('animationend', function (event) {
        // console.log(event);
        // console.log(aRouter.currentRoute);

        defer_right_container_animationend.resolve();

        const currentRoute = aRouter.currentRoute;
        const fullPath = currentRoute.fullPath;

        const query = _.chain(handler_entity_list)
            .find(function (m) {
                const id = m.id;
                return id === fullPath;
            })
            .value()
        ;
        if (!query) return;
        const handler = query.handler;
        if (!_.isFunction(handler)) return;
        handler({
            event,
        });
    });
};

const get_right_container_animationend_promise = function () {
    return defer_right_container_animationend.proimse;
};

export default {
    init,
    on,
    get_right_container_animationend_promise,
};

// 参考
// https://www.runoob.com/jsref/event-animationend.html
// https://developer.mozilla.org/zh-CN/docs/Web/API/HTMLElement/animationend_event
